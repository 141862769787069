/* PrismJS 1.28.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript+http+json+json5+markdown+python+typescript+typoscript+wasm&plugins=line-numbers+show-language+highlight-keywords+toolbar+copy-to-clipboard+download-button */
code[class*="language-"],
pre[class*="language-"] {
	color: #000;
	background: 0 0;
	text-shadow: 0 1px #fff;
	font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
code[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
pre[class*="language-"]::-moz-selection {
	text-shadow: none;
	background: #b3d4fc;
}
code[class*="language-"] ::selection,
code[class*="language-"]::selection,
pre[class*="language-"] ::selection,
pre[class*="language-"]::selection {
	text-shadow: none;
	background: #b3d4fc;
}
@media print {
	code[class*="language-"],
	pre[class*="language-"] {
		text-shadow: none;
	}
}
pre[class*="language-"] {
	padding: 1em;
	margin: 0.5em 0;
	overflow: auto;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #f5f2f0;
}
:not(pre) > code[class*="language-"] {
	padding: 0.1em;
	border-radius: 0.3em;
	white-space: normal;
}
.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
	color: #708090;
}
.token.punctuation {
	color: #999;
}
.token.namespace {
	opacity: 0.7;
}
.token.boolean,
.token.constant,
.token.deleted,
.token.number,
.token.property,
.token.symbol,
.token.tag {
	color: #905;
}
.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
	color: #690;
}
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url {
	color: #9a6e3a;
	background: hsla(0, 0%, 100%, 0.5);
}
.token.atrule,
.token.attr-value,
.token.keyword {
	color: #07a;
}
.token.class-name,
.token.function {
	color: #dd4a68;
}
.token.important,
.token.regex,
.token.variable {
	color: #e90;
}
.token.bold,
.token.important {
	font-weight: 700;
}
.token.italic {
	font-style: italic;
}
.token.entity {
	cursor: help;
}
pre[class*="language-"].line-numbers {
	position: relative;
	padding-left: 3.8em;
	counter-reset: linenumber;
}
pre[class*="language-"].line-numbers > code {
	position: relative;
	white-space: inherit;
}
.line-numbers .line-numbers-rows {
	position: absolute;
	pointer-events: none;
	top: 0;
	font-size: 100%;
	left: -3.8em;
	width: 3em;
	letter-spacing: -1px;
	border-right: 1px solid #999;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.line-numbers-rows > span {
	display: block;
	counter-increment: linenumber;
}
.line-numbers-rows > span:before {
	content: counter(linenumber);
	color: #999;
	display: block;
	padding-right: 0.8em;
	text-align: right;
}
div.code-toolbar {
	position: relative;
}
div.code-toolbar > .toolbar {
	position: absolute;
	z-index: 10;
	top: 0.3em;
	right: 0.2em;
	transition: opacity 0.3s ease-in-out;
	opacity: 0;
}
div.code-toolbar:hover > .toolbar {
	opacity: 1;
}
div.code-toolbar:focus-within > .toolbar {
	opacity: 1;
}
div.code-toolbar > .toolbar > .toolbar-item {
	display: inline-block;
}
div.code-toolbar > .toolbar > .toolbar-item > a {
	cursor: pointer;
}
div.code-toolbar > .toolbar > .toolbar-item > button {
	background: 0 0;
	border: 0;
	color: inherit;
	font: inherit;
	line-height: normal;
	overflow: visible;
	padding: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
div.code-toolbar > .toolbar > .toolbar-item > a,
div.code-toolbar > .toolbar > .toolbar-item > button,
div.code-toolbar > .toolbar > .toolbar-item > span {
	color: #bbb;
	font-size: 0.8em;
	padding: 0 0.5em;
	background: #f5f2f0;
	background: rgba(224, 224, 224, 0.2);
	box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
	border-radius: 0.5em;
}
div.code-toolbar > .toolbar > .toolbar-item > a:focus,
div.code-toolbar > .toolbar > .toolbar-item > a:hover,
div.code-toolbar > .toolbar > .toolbar-item > button:focus,
div.code-toolbar > .toolbar > .toolbar-item > button:hover,
div.code-toolbar > .toolbar > .toolbar-item > span:focus,
div.code-toolbar > .toolbar > .toolbar-item > span:hover {
	color: inherit;
	text-decoration: none;
}
